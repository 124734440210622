$white: #ffffff;
$af_white: #f0f0f0;
$cultured: #f5f6f7;

$black: #000000;
$rich_black: #040405;

$dark_green: #22383c;
$light_green: #96c946;

$orange: #ffa188;
$melon: #f8b3a0;

$misty_rose: #ffe1d9;
$old_rose: #c77e77;

$desert_sand: #e3beba;

$light_silver: #d6d6d6;
$silver_foil: #a8b1b3;

$morning_blue: #909b9d;

$red: #ef3523;
