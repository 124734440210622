/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap";
@import "./styles/variables.scss";

body {
  font-family: "DM Sans";
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

footer,
header {
  flex-shrink: 0;
}

.no-decoration {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.form-input {
  display: flex;
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid $dark_green;
  &:focus {
    box-shadow: rgba(43, 70, 96, 0.2) 0 0 4px;
    border-color: $dark_green;
  }
}

.intl-custom {
  display: flex;
  height: calc(3.5rem + 2px);
  input,
  input[type="text"] {
    @media screen and (max-width: 600px) {
      background-color: transparent !important;
    }
  }
  input:focus {
    @media screen and (max-width: 600px) {
      background-color: $white !important;
    }
  }
}

.iti {
  width: 100%;
}

.info-tooltip {
  .fa-circle-info {
    width: 20px;
    height: auto;
  }
}

.btn {
  border-radius: 0;
  font-weight: bold;
  outline: none;
  min-height: 45px;
  max-height: 45px;
}

.primary,
.primary:hover {
  color: $dark_green;
  background-color: $white;
  border-color: $black;
  box-shadow: 0px 3px 6px #00000029;
  max-height: fit-content;
}

.secondary,
.secondary:hover {
  color: #fff;
  background-color: $dark_green;
  border-color: $dark_green;
  max-height: fit-content;

  &:disabled {
    border: $dark_green solid 2px;
    background-color: $white;
    color: $dark_green;
  }
}

.outline {
  background-color: transparent;
  border: $dark_green solid 2px;
  color: $dark_green;
  max-height: fit-content;
}

.code {
  input {
    font-family: monospace;
    border: 1px solid $black !important;
    border-radius: 0 !important;
    height: 65px !important;
    width: 45px !important;
    -webkit-box-shadow: inset 0 0 10px rgb(210, 210, 210);
    -moz-box-shadow: inset 0 0 10px rgb(210, 210, 210);
    box-shadow: inset 0 0 10px rgb(210, 210, 210);
    background: transparent !important;
    text-align: center !important;
    padding: 0;
    margin: 4px;
    font-size: 32px !important;
    font-weight: 100;
    box-sizing: border-box;
    color: black !important;
  }

  span {
    flex: 0 !important;
  }
}

@media screen and (max-width: 375px) {
  .code {
    input {
      height: 60px !important;
      width: 40px !important;
    }
  }
}

@media screen and (max-width: 320px) {
  .code {
    input {
      height: 50px !important;
      width: 30px !important;
    }
  }
}

.link-text {
  color: $orange;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: $old_rose;
  }
}

.error {
  color: $old_rose;
  margin-right: 5px;
}

.dark-orange {
  color: $old_rose !important;
}

.btn-dark-orange {
  @include button-variant(
    $old_rose,
    $old_rose,
    $white,
    $hover-color: $white,
    $disabled-color: $white
  );
  border-width: 2px;
}

.btn-outline-dark-orange {
  @include button-outline-variant($old_rose, $white, $active-color: $white);
  border-width: 2px;
}

.lazeo-link {
  border: none;
  background: none;
}

.text-font-light {
  font-weight: 400;
}

.orange {
  color: #fff;
  background-color: $orange;
  border-color: $orange;
  max-height: fit-content;
  &:disabled {
    border: $orange solid 2px;
    background-color: $white;
    color: $orange;
  }
  &.outline {
    background-color: transparent;
    border: $orange solid 2px;
    color: $orange;
  }
}

.custom-dialog {
  .modal-content {
    border: 0;
    .dialog-wrapper {
      background-color: #fff;
      border-radius: 0;
      border: 1px solid #c77e77;

      @extend .d-flex;
      @extend .justify-content-center;
      @extend .w-100;
      @extend .flex-wrap;
      @extend .p-4;
      @extend .text-center;
    }
  }
}

.app-label {
  > label {
    padding: 1rem 0;
  }
}

.no-dropdown-arrow.dropdown-toggle::after {
  display: none;
}

.large-text {
  font-weight: 900;
  font-size: 20px;
}

.regular-text {
  font-weight: 500;
  font-size: 16px;
}

.ng-dropdown-panel-items {
  margin-top: 15px;
  border: 1px lightgray solid;
  border-radius: 5px;
  padding: 10px;
  background-color: white;
  line-height: 40px;
}

.ng-value {
  width: 100%;

  .flag-icon {
    margin: 5px;
  }
}

.inputs {
  &.radio {
    width: 1.2em;
    height: 1.2em;
    vertical-align: middle;

    &[type="checkbox"] {
      width: 1.3em;
      height: 1.3em;
      background-color: white;
      border-radius: 50%;
      border: 1px solid #ddd;
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
    }

    &:checked {
      background-color: $orange;
      border-color: $orange;
      box-shadow: 0 4px 20px 0 rgb(0 0 0 / 15%);

      &[type="radio"] {
        appearance: none;
        /* create custom radiobutton appearance */
        display: inline-block;
        padding: 3px;
        /* background-color only for content */
        background-clip: content-box;
        border: 2px solid $orange;
        background-color: $orange;
        border-radius: 50%;
      }

      &[type="checkbox"] {
        border-radius: 100px;
        appearance: none;
        /* create custom radiobutton appearance */
        display: inline-block;
        padding: 3px;
        /* background-color only for content */
        background-clip: content-box;
        border: 2px solid $orange;
        background-color: $orange;
        border-radius: 50%;
      }
    }
  }
}
.app-label {
  label::after {
    background-color: transparent !important;
  }
}

@media screen and (max-width: 600px) {
  .card:not(.appointment-card) {
    padding: 0 !important;
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
    input[type]:not([type="checkbox"]) {
      background-color: transparent !important;
    }
    input[type]:not([type="checkbox"]):focus {
      background-color: white !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .mobile-background {
    background-color: $cultured;
  }
}
